import { transformAttachment, transformAttachmentForServer } from './attachment'

export function transformEmailNotification(notification) {
  return {
    id: notification.id,
    createdAt: notification.createdTime,
    owner: notification.createdById,
    emails: notification.emailIds || [],
    technicianGroups: notification.technicianGroupIds || [],
    requesterGroups: notification.requesterGroupIds || [],
    subject: notification.subject,
    content: notification.emailContent,
    attachments: (notification.fileAttachments || []).map(transformAttachment),
    resourceAsAttachment: notification.resourceAsAttachment,
  }
}

export function transformEmailNotificationForServer(notification) {
  return {
    emailIds: notification.emails,
    technicianGroupIds: notification.technicianGroups,
    requesterGroupIds: notification.requesterGroups,
    subject: notification.subject,
    emailContent: notification.content,
    fileAttachments: (notification.attachments || []).map(
      transformAttachmentForServer
    ),
    resourceAsAttachment: notification.resourceAsAttachment,
  }
}
