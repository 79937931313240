export function transformTenantPrefrences(config) {
  return {
    sendUserActivationMail: config.sendUserActivationMail,
    baseUrl: config.baseUrl,
    considerLastLoginAsUsedBy: config.considerLastLoginAsUsedBy,
    assetBarcodePrefix: config.assetBarcodePrefix,
    softwareAssetPrefix: config.softwareAssetPrefix,
    nonItAssetPrefix: config.nonItAssetPrefix,
    consumableAssetPrefix: config.consumableAssetPrefix,
    hardwareAssetPrefix: config.hardwareAssetPrefix,
    barcodeGenerationStrategy: config.barcodeGenerationStrategy,
    assetUniqueIdentifierSshPropKeys: config.assetUniqueIdentifierSshPropKeys,
    assetUniqueIdentifierWmiPropKeys: config.assetUniqueIdentifierWmiPropKeys,
    assetUniqueIdentifierSnmpPropKeys: config.assetUniqueIdentifierSnmpPropKeys,
    populateLastConversationInReply: config.populateLastConversationInReply,
    defaultSoftwareType: config.defaultSoftwareType,
    shouldFetchSoftwares: config.shouldFetchSoftwares,
    changeStatusInAgentLessDiscovery: config.changeStatusInAgentLessDiscovery,
    changeStatusInAgentBasedDiscovery: config.changeStatusInAgentBasedDiscovery,
    changeAssetTypeInAgentLessDiscovery:
      config.changeAssetTypeInAgentLessDiscovery,
    changeAssetTypeInAgentBasedDiscovery:
      config.changeAssetTypeInAgentBasedDiscovery,
    shouldOverrideAssetDisplayName: config.shouldOverrideAssetDisplayName,
    sharedFolderDetails: config.sharedFolderDetails,
    fetchUsbPortDetails: config.fetchUsbPortDetails,
    autoAskForApproval: config.autoAskForApproval,
    maxFileSizeAllowed: config.maxFileSizeAllowed,
    enableApplicatonAccessibility: config.enableApplicatonAccessibility,
    blockFileAttachment: config.blockFileAttachment,
    allowTechnicianToChangeDoNotDisturb:
      config.allowTechnicianToChangeDoNotDisturb,
    defaultDashboardId: config.defaultDashboardId,
    emailCommand: config.emailCommand,
    qrCodeSupport: config.qrCodeSupport,
    qrCodeMandatoryPropKeys: config.qrCodeMandatoryPropKeys,
    qrCodeCustomfieldKeys: config.qrCodeCustomfieldKeys,
    selfServiceSetting: config.selfServiceSetting,
    enabledLanguages: config.enabledLanguages,
    minQuestionsInSurvey: config.minQuestionsInSurvey,
    minAudienceSize: config.minAudienceSize,
    enableAutoUnistalltionSoftware: config.enableAutoUnistalltionSoftware,
    excludeUsersFromIpRistriction: config.excludeUsersFromIpRistriction,
    autoUnistalltionDays: config.autoUnistalltionDays,
    enableEmailToRequestForGuestUser: config.enableEmailToRequestForGuestUser,
    enableEmailToRequest: config.enableEmailToRequest,
    useReplyToEmailAddressToCreateRequester:
      config.useReplyToEmailAddressToCreateRequester,
    useOriginalSenderAsRequester: config.useOriginalSenderAsRequester,
    closedRequestAction: config.closedRequestAction,
    reopenRequestGracePeriodDays: config.reopenRequestGracePeriodDays,
    feedbackRatingValues: (config.feedbackRatingValues || []).map(
      (value, index) => {
        return {
          id: index + 1,
          index: index,
          rating: index + 1,
          description: value,
        }
      }
    ),
    purchasePrefix: config.purchasePrefix,
    customFieldSearch: config.customFieldSearch,
    serviceCatalogCustomFieldSearch: config.serviceCatalogCustomFieldSearch,
    conversationSearch: config.conversationSearch,
    enableAssignmentRule: config.enableAssignmentRule,
    thresholdLeaveBalance: config.thresholdLeaveBalance,
    fileAttachmentSearch: config.fileAttachmentSearch,
    softwareAutoUnistallTime: config.softwareAutoUnistallTime,
    softwareAutoUnistallTimeUnit: config.softwareAutoUnistallTimeUnit,
    saveRepliesConversationInDraft: config.saveRepliesConversationInDraft,
    assetConfirmationByUser: config.assetConfirmationByUser,
    assetTypeIds: config.assetTypeIds,
    acceptancePeriod: config.acceptancePeriod,
    acceptancePeriodTimeUnit: config.acceptancePeriodTimeUnit || 'days',
    reminderInterval: config.reminderInterval,
    reminderIntervalTimeUnit: config.reminderIntervalTimeUnit || 'hours',
  }
}

export function transformTenantPrefrencesForServer(config) {
  const transformedProps = ['feedbackRatingValues']
  const obj = {
    ...(config.feedbackRatingValues
      ? {
          feedbackRatingValues: (config.feedbackRatingValues || []).map(
            (rating) => {
              return rating.description
            }
          ),
        }
      : {}),
  }
  Object.keys(config).forEach((paramName) => {
    if (!transformedProps.includes(paramName)) {
      obj[paramName] = config[paramName]
    }
  })
  return obj
}
