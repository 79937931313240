/**
 * transform user received from server
 */
import Moment from 'moment'
import { getRootPluaralTranslator } from '@/src/utils/get-module-translator'
// import { token } from '@utils/auth'
import { buildImageUrlFromRefFileName } from './attachment'
const __rootTc = getRootPluaralTranslator()

export function buildAvatar(user, isRequester) {
  if (!user.name && !user.email) {
    return undefined
  }
  let color = 'ffffff'
  let background = '3279be'
  if (isRequester) {
    color = 'ffffff'
    background = 'f58518'
  }
  if ((user.profilePic || {}).refFileName) {
    return buildImageUrlFromRefFileName(user.profilePic.refFileName)
  }
  const nameString = (user.name || user.email).toUpperCase()
  const matches = nameString.match(/\b(\w)/g) || []
  const name =
    matches.length > 1 ? matches.join('').substr(0, 2) : nameString.substr(0, 2)
  // GET sample avatar url
  return `/api/public/avatar/?size=180&color=${color}&background=${background}&name=${name}`
  // return `https://ui-avatars.com/api/?size=180&color=${color}&background=${background}&name=${(
  //   user.name || user.email
  // ).substr(0, 2)}`
}

export function transformTechnicianPickerOptions(u) {
  return {
    key: u.id,
    id: u.id,
    name: u.name,
    email: u.email,
    avatar: u.avatar,
    loadIndicator: u.loadIndicator || 'low',
    groups: u.groups,
    isTenant: u.isTenant,
    archived: u.archived,
    blocked: u.blocked,
    allowToLogin: u.allowToLogin,
    isSuperAdmin: u.isSuperAdmin,
    superAdminRole: u.superAdminRole,
    availabilityStatusId: u.availabilityStatusId,
    thresholdViolated: u.thresholdViolated,
  }
}

export function transformFieldMappingItems(user) {
  return {
    managerName: user.managerName,
    locationId: user.locationId,
    departmentId: user.departmentId,
    fieldValueDetails: user.fieldValueDetails,
  }
}
export function transformContactOpsItems(user) {
  return {
    coOpsRole: user.coOpsRole,
    coOpsExtensionType: user.coOpsExtensionType,
    coOpsExtensionNumber: user.coOpsExtensionNumber,
  }
}

export function transformUserForVuex(user) {
  const transformedUser = {
    id: user.id,
    name: user.name,
    email: user.email,
    systemUser: user.email === 'system@flotomate.com',
    loginSource: user.loginSource,
    groups: user.groups,
    loadIndicator: user.loadIndicator,
    isTenant: user.deleteableOob === false,
    archived: user.removed,
    blocked: user.disabled,
    isSuperAdmin: user.isSuperAdmin,
    allowToLogin: user.allowToLogin,
    cxoUser: user.cxoUser,
    superAdminRole: user.superAdminRole,
    userLogOnName: user.userLogOnName,
    managerId: user.managerId,
    department: user.departmentId,
    companyId: user.companyIds,
    location: user.locationId,
    contactNo: user.contactNo,
    altContactNo1: user.altContactNo1,
    altContactNo2: user.altContactNo2,
    coOpsRole: user.coOpsRole,
    supportLevel: user.supportLevel,
    mfaEnrolled: user.mfaEnrolled,
    enableDndSchedule: user.enableDndSchedule,
    availabilityStatusId: user.availabilityStatusId,
    thresholdViolated: user.thresholdViolated,
    ...(user.dndSchedule
      ? {
          startAt: user.dndSchedule.startAt,
          endAt: user.dndSchedule.endAt,
        }
      : {}),
    ...(user.dateTimeFormat
      ? {
          dateTimeFormat: Moment().toMomentFormatString(user.dateTimeFormat),
        }
      : {}),
    ...(user.dateFormat
      ? {
          dateFormat: Moment().toMomentFormatString(user.dateFormat),
        }
      : {}),
    ...(user.timeFormat
      ? {
          timeFormat: Moment().toMomentFormatString(user.timeFormat),
        }
      : {}),
    ...transformFieldMappingItems(user),
    ...transformContactOpsItems(user),
  }
  transformedUser.avatar = buildAvatar(user, user.userType === 'requester')
  return transformedUser
}

export function transformSuperAdminForServer(user) {
  return {
    newUserId: user.newUserId,
    archive: user.archive,
  }
}

export function transformUserForList(user) {
  return {
    id: user.id,
    name: user.name,
    text: user.removed
      ? `${user.name} ${user.email ? `(${user.email})` : ''} (${__rootTc(
          'archived'
        )})`
      : `${user.name} ${user.email ? `(${user.email})` : ''}`,
    avatar: buildAvatar(user, user.userType === 'requester'),
    email: user.email,
    systemUser: user.email === 'system@flotomate.com',
    loginSource: user.loginSource,
    department: user.departmentId,
    contactNo: user.contactNo,
    altContactNo1: user.altContactNo1,
    altContactNo2: user.altContactNo2,
    location: user.locationId,
    verified: user.verified,
    archived: user.removed,
    canDelete: user.deleteableOob,
    canEdit: user.updatebleOob,
    userType: user.userType,
    userLogOnName: user.userLogOnName,
    ldapUser: user.ldapUser,
    managerId: user.managerId,
    blocked: user.disabled,
    locked: user.locked,
    canSetPassword: user.enableOneTimePasswordForRequester,
    isSuperAdmin: user.isSuperAdmin,
    superAdminRole: user.superAdminRole,
    coOpsRole: user.coOpsRole,
    allowToLogin: user.allowToLogin,
    cxoUser: user.cxoUser,
    vip: user.vip,
    groups: user.groups,
    supportLevel: user.supportLevel,
    timezone: user.timezone,
    scope: user.accessLevel,
    doNotDisturb: user.doNotDisturb,
    companyId: user.companyIds,
    allowDelegateApproval: user.allowDelegateApproval,
    allowDelegateApprovalTeam: user.allowDelegateApprovalTeam,
    ssoConfigId: user.ssoConfigId,
    availabilityStatusId: user.availabilityStatusId,
    thresholdViolated: user.thresholdViolated,
    ...(user.fieldValueDetails || {}),
    ...(user.dateTimeFormat
      ? {
          dateTimeFormat: Moment().toMomentFormatString(user.dateTimeFormat),
        }
      : {}),
    ...(user.dateFormat
      ? {
          dateFormat: Moment().toMomentFormatString(user.dateFormat),
        }
      : {}),
    ...(user.timeFormat
      ? {
          timeFormat: Moment().toMomentFormatString(user.timeFormat),
        }
      : {}),
  }
}

export function transformUser(user) {
  const listProps = transformUserForList(user)
  return {
    ...listProps,
    id: user.id,
    name: user.removed ? `${user.name} (${__rootTc('archived')})` : user.name,
    email: user.email,
    ...(user.password ? { password: user.password } : {}),
    systemUser: user.email === 'system@flotomate.com',
    loginSource: user.loginSource,
    timezone: user.timezone,
    location: user.locationId,
    signature: user.signature,
    department: user.departmentId,
    userLogOnName: user.userLogOnName,
    ldapUser: user.ldapUser,
    authenticationSource: user.authenticationSource,
    managerId: user.managerId,
    groups: user.groups,
    requesterGroupId: user.requesterGroupId,
    supportLevel: user.supportLevel,
    roles: user.roles,
    scope: user.accessLevel,
    profilePic: user.profilePic,
    avatar: buildAvatar(user, user.userType === 'requester'),
    fieldValueDetails: user.fieldValueDetails,
    userType: user.userType,
    vip: user.vip,
    contactNo: user.contactNo,
    altContactNo1: user.altContactNo1,
    altContactNo2: user.altContactNo2,
    isSuperAdmin: user.isSuperAdmin,
    superAdminRole: user.superAdminRole,
    doNotDisturb: user.doNotDisturb,
    allowToLogin: user.allowToLogin,
    cxoUser: user.cxoUser,
    canDelete: user.deleteableOob,
    verified: user.verified,
    archived: user.removed,
    blocked: user.disabled,
    userLanguage: user.userLanguage,
    delegatorApproval: user.delegatorApproval,
    locked: user.locked,
    canSetPassword: user.EnableOneTimePasswordForRequester,
    coOpsRole: user.coOpsRole,
    ...('companyIds' in user
      ? {
          ...(user.userType === 'requester'
            ? {
                companyId: (user.companyIds || [])[0],
              }
            : {
                companyId: user.companyIds || [],
              }),
        }
      : {}),
    restrictedCompanyAccess: user.restrictedCompanyAccess,
    mfaEnrolled: user.mfaEnrolled,
    allowDelegateApproval: user.allowDelegateApproval,
    allowDelegateApprovalTeam: user.allowDelegateApprovalTeam,
    ...transformFieldMappingItems(user),
    ...transformContactOpsItems(user),
    ssoConfigId: user.ssoConfigId,
    ssoUser: user.ssoUser,
    enableDndSchedule: user.enableDndSchedule,
    ...(user.dndSchedule
      ? {
          startAt: user.dndSchedule.startAt,
          endAt: user.dndSchedule.endAt,
        }
      : {}),
  }
}

export function transformUserForServer(user) {
  return {
    name: user.name,
    email: user.email,
    loginSource: user.loginSource,
    ...(user.password ? { password: user.password } : {}),
    ...(user.userType ? { userType: user.userType } : {}),
    ...(user.oneTimePassword ? { oneTimePassword: user.oneTimePassword } : {}),
    timezone: user.timezone,
    locationId: user.location,
    signature: user.signature ? user.signature : null,
    departmentId: user.department,
    groups: user.groups,
    supportLevel: user.supportLevel,
    roles: user.roles,
    accessLevel: user.scope,
    profilePic: user.profilePic,
    vip: user.vip,
    verified: user.verified,
    fieldValueDetails: user.fieldValueDetails,
    contactNo: user.contactNo,
    altContactNo1: user.altContactNo1,
    altContactNo2: user.altContactNo2,
    isSuperAdmin: user.isSuperAdmin,
    superAdminRole: user.superAdminRole,
    doNotDisturb: user.doNotDisturb,
    allowToLogin: user.allowToLogin,
    cxoUser: user.cxoUser,
    userLogOnName: user.userLogOnName,
    ldapUser: user.ldapUser,
    authenticationSource: user.authenticationSource,
    managerId: user.managerId,
    disabled: user.blocked,
    userLanguage: user.userLanguage,
    callFrom: user.callFrom,
    ...('companyId' in user
      ? {
          ...(user.userType === 'requester'
            ? {
                companyIds:
                  user.companyId && user.companyId ? [user.companyId] : [],
              }
            : { companyIds: user.companyId }),
        }
      : {}),
    restrictedCompanyAccess: user.restrictedCompanyAccess,
    allowDelegateApproval: user.allowDelegateApproval,
    allowDelegateApprovalTeam: user.allowDelegateApprovalTeam,
    ssoConfigId: user.ssoConfigId,
    enableDndSchedule: user.enableDndSchedule,
    ...(user.enableDndSchedule
      ? {
          dndSchedule: {
            type: 'FlotoTimeSpanScheduleRest',
            startAt: user.startAt,
            endAt: user.endAt,
          },
        }
      : {}),
    ...(user.dateTimeFormat
      ? {
          dateTimeFormat: Moment().toJDFString(user.dateTimeFormat),
        }
      : {}),
    ...(user.dateFormat
      ? {
          dateFormat: Moment().toJDFString(user.dateFormat),
        }
      : {}),
    ...(user.timeFormat
      ? {
          timeFormat: Moment().toJDFString(user.timeFormat),
        }
      : {}),
  }
}
