export function transformUserSecurity(usersecurity) {
  return {
    enabled2fa: usersecurity.enabled2fa,
    twoFactorAuthenticationType: usersecurity.twoFactorAuthenticationType,
    lockUserAccount: usersecurity.lockUserAccount,
    loginAttempts: usersecurity.loginAttempts,
    sessionIdleTimeOutUnit: usersecurity.sessionIdleTimeOutUnit,
    sessionTimeOutUnit: usersecurity.sessionTimeOutUnit,
    enbleSessionTimeout: usersecurity.enbleSessionTimeout,
    sessionIdleTimeOut: usersecurity.sessionIdleTimeOut,
    sessionTimeOut: usersecurity.sessionTimeOut,
    disabledConcurrentLoggedIn: usersecurity.disabledConcurrentLoggedIn,
    enableCaptcha: usersecurity.enableCaptcha,
    captchaType: usersecurity.captchaType,
    site: usersecurity.site,
    secret: usersecurity.secret,
    enableCaptchaForGuestUser: usersecurity.enableCaptchaForGuestUser,
    enabledGoogleLogin: usersecurity.enabledGoogleLogin,
    googleWebClientId: usersecurity.googleWebClientId,
    googleAndroidClientId: usersecurity.googleAndroidClientId,
    googleIosClientId: usersecurity.googleIosClientId,
    googleAndroidPackageId: usersecurity.googleAndroidPackageId,
    googleAndroidSHA1CirtificateFingerPrint:
      usersecurity.googleAndroidSHA1CirtificateFingerPrint,
    googleIosBundelId: usersecurity.googleIosBundelId,
    googleIosAppleId: usersecurity.googleIosAppleId,
    googleIosTeamId: usersecurity.googleIosTeamId,
  }
}

export function transformUserSecurityForServer(usersecurity) {
  return {
    enabled2fa: usersecurity.enabled2fa,
    twoFactorAuthenticationType: usersecurity.twoFactorAuthenticationType,
    lockUserAccount: usersecurity.lockUserAccount,
    loginAttempts: usersecurity.loginAttempts,
    sessionIdleTimeOutUnit: usersecurity.sessionIdleTimeOutUnit,
    sessionTimeOutUnit: usersecurity.sessionTimeOutUnit,
    enbleSessionTimeout: usersecurity.enbleSessionTimeout,
    sessionIdleTimeOut: usersecurity.sessionIdleTimeOut,
    sessionTimeOut: usersecurity.sessionTimeOut,
    disabledConcurrentLoggedIn: usersecurity.disabledConcurrentLoggedIn,
    enableCaptcha: usersecurity.enableCaptcha,
    captchaType: usersecurity.captchaType,
    site: usersecurity.site,
    secret: usersecurity.secret,
    enableCaptchaForGuestUser: usersecurity.enableCaptcha
      ? usersecurity.enableCaptchaForGuestUser
      : false,
    enabledGoogleLogin: usersecurity.enabledGoogleLogin,
    googleWebClientId: usersecurity.googleWebClientId,
    googleAndroidClientId: usersecurity.googleAndroidClientId,
    googleIosClientId: usersecurity.googleIosClientId,
    googleAndroidPackageId: usersecurity.googleAndroidPackageId,
    googleAndroidSHA1CirtificateFingerPrint:
      usersecurity.googleAndroidSHA1CirtificateFingerPrint,
    googleIosBundelId: usersecurity.googleIosBundelId,
    googleIosAppleId: usersecurity.googleIosAppleId,
    googleIosTeamId: usersecurity.googleIosTeamId,
  }
}
