import Invert from 'lodash/invert'
import Capitalize from 'lodash/capitalize'

const availableValueType = {
  bigString: 'BigStringValueRest',
  string: 'StringValueRest',
  boolean: 'BooleanValueRest',
  double: 'DoubleValueRest',
  enum: 'EnumValueRest',
  integer: 'IntegerValueRest',
  long: 'LongValueRest',
  listDouble: 'ListDoubleValueRest',
  listString: 'ListStringValueRest',
  listInteger: 'ListIntegerValueRest',
  listLong: 'ListLongValueRest',
  listEnum: 'ListEnumValueRest',
  attachment: 'AttachmentValueRest',
  field: 'FieldValueRest',
  time: 'TimeValueRest',
}

export function transformValueType(value) {
  if (!value) {
    return
  }
  const reverseMap = Invert(availableValueType)
  const valueType = reverseMap[value.type]
  if (!valueType) {
    throw new Error(`${value.type} type is not defined`)
  }
  if (valueType === 'time') {
    return {
      value: {
        timeUnit: value.timeUnit,
        value: value.value,
      },
      valueType,
    }
  }
  return {
    value: value.value,
    valueType,
  }
}

export function transformValueTypeForServer(inputType, value) {
  if (Array.isArray(value) && /^list/.test(inputType) === false) {
    inputType = `list${Capitalize(inputType)}`
  }
  if (!Array.isArray(value) && /^list/.test(inputType)) {
    inputType = inputType.replace('list', '').toLowerCase()
  }
  if (inputType === 'big_string') {
    inputType = 'bigString'
  }
  const type = availableValueType[inputType]
  if (!type) {
    throw new Error(`${type} is not allowed`)
  }
  if (inputType === 'time') {
    return {
      value: {
        type,
        timeUnit: value.timeUnit,
        value: value.value,
      },
    }
  }
  if (inputType === 'field') {
    return {
      value: {
        type,
        value,
        customField: /^\d+$/.test(value),
      },
    }
  }
  if (inputType === 'enum') {
    return {
      value: {
        type,
        value: value,
      },
    }
  }
  if (inputType === 'boolean' && ['true', 'false'].indexOf(value) >= 0) {
    return {
      value: {
        type,
        value: value === 'true',
      },
    }
  }
  if (inputType === 'dropdown' && ['true', 'false'].indexOf(value) >= 0) {
    return {
      value: {
        type,
        value: value === 'true',
      },
    }
  }
  return {
    value: {
      type,
      value,
    },
  }
}

export function clearValueForCustomField(inputType) {
  switch (inputType) {
    case 'number':
    case 'datetime':
    case 'dependent':
      return 0
    case 'checkbox':
      return []
    case 'radio':
      return ''
    case 'dropdown':
    case 'textarea':
    case 'richtextarea':
    case 'text':
      return ''
    case 'boolean':
      return false
    default:
      return null
  }
}
