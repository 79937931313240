import {
  transformValueType,
  transformValueTypeForServer,
} from '@data/value-type'
import { getEncryptedPassword, getDecryptedPassword } from '@utils/password'
import {
  transformAttachment,
  transformAttachmentForServer,
} from '@data/attachment'

export function transformQuestions(data) {
  return {
    id: data.id,
    key: data.question,
    text: data.question,
    name: data.question,
    canEdit: data.updatebleOob,
    canDelete: data.deleteableOob,
    disabled: false,
  }
}

export function transformQuestionsForServer(data) {
  return {
    question: data.name,
  }
}

export function transformIncomingEmailConfigForServer(config) {
  return {
    name: config.name,
    server: config.server,
    protocol: config.protocol,
    password: config.password,
    replyToEmail: config.replyToEmail,
    senderName: config.senderName,
    disabled: !config.enabled,
    asssignGroupId: config.technicianGroups,
    isPrimary: config.primary,
    email: config.email,
    enableOutgoingMail: config.enableOutgoingMail,
    companyId: config.companyId,
    categoryId: config.categoryId,
    emailAuthType: config.emailAuthType,
    ...(config.emailAuthType === 'oauth'
      ? {
          clientId: config.clientId
            ? getEncryptedPassword(config.clientId)
            : undefined,
          clientSecret: config.clientSecret
            ? getEncryptedPassword(config.clientSecret)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
          code: config.code,
        }
      : {}),
    ...(config.enableOutgoingMail
      ? {
          outMailConfigID: config.outMailConfigID,
        }
      : {}),
    ...(config.protocol !== 'mapi'
      ? {
          port: config.port,
          securityType: config.securityType,
        }
      : {}),
    ...(config.spamFilter
      ? {
          spamFilter: {
            spamKeyword: config.spamFilter.spamKeyword,
            filterType: config.spamFilter.filterType,
            emails: config.spamFilter.emails,
            domains: config.spamFilter.domains,
          },
        }
      : {}),
  }
}

export function transformIncomingEmailConfig(config) {
  return {
    id: config.id,
    name: config.name,
    server: config.server,
    port: config.port,
    protocol: config.protocol,
    securityType: config.securityType,
    email: config.email,
    password: config.password,
    replyToEmail: config.replyToEmail,
    senderName: config.senderName,
    enabled: !config.disabled,
    technicianGroups: config.asssignGroupId,
    primary: config.isPrimary,
    enableOutgoingMail: config.enableOutgoingMail,
    outMailConfigID: config.outMailConfigID,
    companyId: config.companyId,
    categoryId: config.categoryId,
    emailAuthType: config.emailAuthType,
    ...(config.emailAuthType === 'oauth'
      ? {
          clientId: config.clientId
            ? getDecryptedPassword(config.clientId)
            : undefined,
          clientSecret: config.clientSecret
            ? getDecryptedPassword(config.clientSecret)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
        }
      : {}),
    ...(config.spamFilter
      ? {
          spamFilter: {
            spamKeyword: config.spamFilter.spamKeyword,
            filterType: config.spamFilter.filterType,
            emails: config.spamFilter.emails,
            domains: config.spamFilter.domains,
          },
        }
      : {}),
  }
}

export const defaultIncomingEmailConfig = {
  enabled: true,
  emailAuthType: 'basic_auth',
  spamFilter: {
    spamKeyword: undefined,
    filterType: 'ignore',
    emails: undefined,
    domains: undefined,
  },
}

export const defaultOutgoingEmailConfig = {
  enabled: true,
  emailAuthType: 'basic_auth',
}

export function transformOutgoingEmailConfig(config) {
  return {
    id: config.id,
    name: config.name,
    server: config.server,
    protocol: config.protocol,
    port: config.port,
    securityType: config.securityType,
    email: config.email,
    userName: config.userName,
    password: config.password,
    replyToEmail: config.replyToEmail,
    senderName: config.senderName,
    enabled: !config.disabled,
    primary: config.isDefault,
    authenticationNeeded: config.authenticationNeeded,
    emailAuthType: config.emailAuthType,
    ...(config.emailAuthType === 'oauth'
      ? {
          clientId: config.clientId
            ? getDecryptedPassword(config.clientId)
            : undefined,
          clientSecret: config.clientSecret
            ? getDecryptedPassword(config.clientSecret)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
        }
      : {}),
  }
}

export function transformEmailConfigForServer(config) {
  return {
    server: config.server,
    protocol: config.protocol,
    password: config.password || '',
    replyToEmail: config.replyToEmail,
    disabled: !config.enabled,
    authenticationNeeded: config.authenticationNeeded,
    email: config.email,
    userName: config.userName,
    emailAuthType: config.emailAuthType,
    ...(config.emailAuthType === 'oauth'
      ? {
          ...(config.protocol !== 'imap'
            ? {
                clientSecret: config.clientSecret
                  ? getEncryptedPassword(config.clientSecret)
                  : undefined,
              }
            : {}),
          clientId: config.clientId
            ? getEncryptedPassword(config.clientId)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
        }
      : {}),
    ...(config.protocol !== 'mapi'
      ? {
          port: config.port,
          securityType: config.securityType,
          senderName: config.senderName,
        }
      : {}),
  }
}

export function transformOutgoingEmailConfigForServer(config) {
  return {
    name: config.name,
    server: config.server,
    protocol: config.protocol,
    disabled: !config.enabled,
    email: config.email,
    password: config.password || '',
    replyToEmail: config.replyToEmail,
    authenticationNeeded: config.authenticationNeeded,
    isDefault: config.primary,
    userName: config.userName,
    emailAuthType: config.emailAuthType,
    ...(config.emailAuthType === 'oauth'
      ? {
          clientId: config.clientId
            ? getEncryptedPassword(config.clientId)
            : undefined,
          clientSecret: config.clientSecret
            ? getEncryptedPassword(config.clientSecret)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
          code: config.code,
        }
      : {}),
    ...(config.protocol !== 'mapi'
      ? {
          port: config.port,
          securityType: config.securityType,
          senderName: config.senderName,
        }
      : {}),
  }
}

// support portal configuration transform
export function transformSupportPortalConfig(config) {
  return {
    allowGuestToReportRequest: config.allowGuestToReportRequest,
    allowSelfRegistration: config.allowSelfRegistration,
    selfRegistrationType: config.selfRegistrationType,
    selfServiceSetting: config.selfServiceSetting,
    domains: config.domainNames,
    showRelatedKnowledge: config.showRelatedKnowledge,
    allowToLinkAsset: config.allowToLinkAsset,
    allowToReopenClosedReq: config.allowToReopenClosedReq,
    allowToCloseReq: config.allowToCloseReq,
    closedGraceTimeType: config.closedGraceTimeType,
    closedGraceTime: config.closedGraceTime,
    allowToReopenResolvedReq: config.allowToReopenResolvedReq,
    resolvedGraceTimeType: config.resolvedGraceTimeType,
    resolvedGraceTime: config.resolvedGraceTime,
    allowReqToAccessMyAsset: config.allowReqToAccessMyAsset,
    allowRequesterToViewBarcodeAndQRCode:
      config.allowRequesterToViewBarcodeAndQRCode,
    allowRequesterToAccessKB: config.allowRequesterToAccessKB,
    allowRequesterToAccessServiceRequest:
      config.allowRequesterToAccessServiceRequest,
    allowRequesterToAccessMyApprovals: config.allowRequesterToAccessMyApprovals,
    allowRequesterToSubmitFeedback: config.allowRequesterToSubmitFeedback,
    allowRequesterToAccestTicketApprovalTab: config.showApprovalTab,
    allowReqToAccessMyChanges: config.allowReqToAccessMyChanges,
    allowRequesterToCreateRequestBehalfOfOtherRequester:
      config.allowRequesterToCreateRequestBehalfOfOtherRequester,
    allowRequesterToCreateIncidentBehalfOfOtherRequester:
      config.allowRequesterToCreateIncidentBehalfOfOtherRequester,
  }
}

export function transformSupportPortalConfigForServer(config) {
  return {
    allowGuestToReportRequest: config.allowGuestToReportRequest,
    allowSelfRegistration: config.allowSelfRegistration,
    selfRegistrationType: config.selfRegistrationType,
    domainNames: config.domains,
    showRelatedKnowledge: config.showRelatedKnowledge,
    allowToLinkAsset: config.allowToLinkAsset,
    allowToReopenClosedReq: config.allowToReopenClosedReq,
    allowToCloseReq: config.allowToCloseReq,
    closedGraceTimeType: config.closedGraceTimeType,
    closedGraceTime: config.closedGraceTime,
    allowToReopenResolvedReq: config.allowToReopenResolvedReq,
    resolvedGraceTimeType: config.resolvedGraceTimeType,
    resolvedGraceTime: config.resolvedGraceTime,
    allowReqToAccessMyAsset: config.allowReqToAccessMyAsset,
    allowRequesterToViewBarcodeAndQRCode:
      config.allowRequesterToViewBarcodeAndQRCode,
    allowRequesterToAccessKB: config.allowRequesterToAccessKB,
    allowRequesterToAccessServiceRequest:
      config.allowRequesterToAccessServiceRequest,
    allowRequesterToAccessMyApprovals: config.allowRequesterToAccessMyApprovals,
    allowRequesterToSubmitFeedback: config.allowRequesterToSubmitFeedback,
    showApprovalTab: config.allowRequesterToAccestTicketApprovalTab,
    allowReqToAccessMyChanges: config.allowReqToAccessMyChanges,
    allowRequesterToCreateRequestBehalfOfOtherRequester:
      config.allowRequesterToCreateRequestBehalfOfOtherRequester,
    allowRequesterToCreateIncidentBehalfOfOtherRequester:
      config.allowRequesterToCreateIncidentBehalfOfOtherRequester,
  }
}

// chat configuration

export function transformChatConfig(config) {
  return {
    id: config.id,
    enabled: !config.disabled,
    userIds: config.userIds,
    groupIds: config.groupIds,
    welcomeMessage: config.welcomeMessage,
    missedMessage: config.missedMessage,
    timeout: config.timeout,
    collaborationSupport: config.collaborationSupport,
    botSupport: config.botSupport,
    greetingStoryId: config.greetingStoryId,
    fallbackStoryId: config.fallbackStoryId,
    chatLogo: config.chatLogo
      ? (config.chatLogo || []).map(transformAttachment)
      : undefined,
    ...((config.chatLogo || []).length
      ? {
          chatLogoSrc: `/api/public/download/chat/logo`,
        }
      : {}),
  }
}

export function transformChatConfigForServer(config) {
  return {
    id: config.id,
    disabled: !config.enabled,
    userIds: config.userIds,
    groupIds: config.groupIds,
    welcomeMessage: config.welcomeMessage,
    missedMessage: config.missedMessage,
    timeout: config.timeout,
    collaborationSupport: config.collaborationSupport,
    botSupport: config.botSupport,
    greetingStoryId: config.greetingStoryId || 0,
    fallbackStoryId: config.fallbackStoryId || 0,
    chatLogo:
      config.chatLogo && config.chatLogo.length
        ? (config.chatLogo || []).map(transformAttachmentForServer)
        : [],
  }
}

// prefrences transform

export const transformPreferences = (preferences, allowedKeys) => {
  const filteredPreference = (preferences.preferences || []).filter(
    (preference) => {
      return allowedKeys.indexOf(preference.propertyKey) >= 0
    }
  )
  const map = {}
  filteredPreference.forEach((preference) => {
    map[preference.propertyKey] = {
      propertyKey: preference.propertyKey,
      ...transformValueType(preference.valueRest),
    }
  })
  return map
}
export const transformPreferencesForServer = (preference) => ({
  preferences: [
    {
      propertyKey: preference.propertyKey,
      valueRest: transformValueTypeForServer(
        preference.valueType,
        preference.value
      ).value,
    },
  ],
})
