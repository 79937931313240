import {
  tranformFlotoScheduleForServer,
  transformFlotoSchedule,
} from '@data/report'
import { generateId } from '@utils/id'

export function transformSurveyDashlet(dashlet) {
  return {
    id: dashlet.dashLets,
    i: generateId(),
    type: dashlet.type,
    visulizationType: (dashlet.visualizationType || '').toLowerCase(),
  }
}

export function transformUserSurvey(userSurvey) {
  return {
    id: userSurvey.id,
    name: userSurvey.name,
    displayName: userSurvey.displayName,
    subject: userSurvey.displayName,
    description: userSurvey.description,
    surveyStatus: userSurvey.surveyStatus,
    surveyStartDate: userSurvey.surveyStartDate,
    totalAudience: userSurvey.totalAudience,
    surveyEndDate: userSurvey.surveyEndDate,
    validityDays: userSurvey.validityDays,
    owner: userSurvey.owner,
    departmentId: userSurvey.departmentId,
    locationId: userSurvey.locationId,
    requesterGroup: userSurvey.requesterGroup,
    serviceCatalogId: userSurvey.serviceCatalogId,
    tags: userSurvey.tags,
    source: userSurvey.source,
    excludeTechnicians: userSurvey.excludeTechnicians,
    surveyTitle: userSurvey.surveyTitle,
    surveySummary: userSurvey.surveySummary,
    minResponse: userSurvey.minResponse,
    allowComments: userSurvey.allowComments,
    emailSubject: userSurvey.emailSubject,
    emailContent: userSurvey.emailContent,
    lastClosedTime: userSurvey.lastClosedTime,
    lastCancelledTime: userSurvey.lastCancelledTime,
    archived: userSurvey.removed,
    createdAt: userSurvey.createdTime,
    updatedAt: userSurvey.updatedTime,
    updatedTime: userSurvey.updatedTime,
    createdBy: userSurvey.createdById,
    requesterName: userSurvey.requesterName,
    scheduleId: userSurvey.scheduleId,
    totalRequests: userSurvey.totalRequests,
    receivedResponses: userSurvey.receivedResponses,
    pendingResponses: userSurvey.pendingResponses,
    completion: userSurvey.completion,
    companyId: userSurvey.companyId,
    fieldValueDetails: userSurvey.fieldValueDetails || {},
    ...(userSurvey.fieldValueDetails || {}),
  }
}

export const transformSurveyResponseForServer = (survey) => {
  const obj = {
    ...(survey.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...survey.fieldValueDetails,
          },
        }
      : {}),
    additionalComments: survey.additionalComments,
  }
  Object.keys(survey).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = survey[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: survey[paramName],
        }
      }
    } else {
      obj[paramName] = survey[paramName]
    }
  })
  return obj
}

export function transformUserSurveyForServer(userSurvey) {
  const transformedProps = ['fieldValueDetails']
  const obj = {
    ...(userSurvey.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...userSurvey.fieldValueDetails,
          },
        }
      : {}),
  }
  Object.keys(userSurvey).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = userSurvey[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: userSurvey[paramName],
        }
      }
    } else if (!transformedProps.includes(paramName)) {
      obj[paramName] = userSurvey[paramName]
    }
  })
  if ('description' in userSurvey) {
    obj.description = userSurvey.description || ''
  }
  return obj
}

export function transformScheduleSurveyForServer(scheduleSurvey) {
  if (scheduleSurvey.flotoSchedule) {
    return {
      surveyScheduleStatus: scheduleSurvey.status,
      displayName: scheduleSurvey.displayName,
      description: scheduleSurvey.description,
      validityDays: scheduleSurvey.validityDays,
      serviceCatalogId: scheduleSurvey.serviceCatalogId,
      owner: scheduleSurvey.owner,
      departmentId: scheduleSurvey.departmentId,
      locationId: scheduleSurvey.locationId,
      tags: scheduleSurvey.tags,
      requesterGroup: scheduleSurvey.requesterGroup,
      excludeTechnicians: scheduleSurvey.excludeTechnicians,
      surveyTitle: scheduleSurvey.surveyTitle,
      surveySummary: scheduleSurvey.surveySummary,
      minResponse: scheduleSurvey.minResponse,
      allowComments: scheduleSurvey.allowComments,
      emailSubject: scheduleSurvey.emailSubject,
      emailContent: scheduleSurvey.emailContent,
      companyId: scheduleSurvey.companyId,
      flotoSchedule: tranformFlotoScheduleForServer(
        scheduleSurvey.flotoSchedule
      ),
    }
  }
  return {
    surveyScheduleStatus: scheduleSurvey.status,
    displayName: scheduleSurvey.displayName,
    description: scheduleSurvey.description,
    validityDays: scheduleSurvey.validityDays,
    serviceCatalogId: scheduleSurvey.serviceCatalogId,
    owner: scheduleSurvey.owner,
    departmentId: scheduleSurvey.departmentId,
    locationId: scheduleSurvey.locationId,
    tags: scheduleSurvey.tags,
    requesterGroup: scheduleSurvey.requesterGroup,
    excludeTechnicians: scheduleSurvey.excludeTechnicians,
    surveyTitle: scheduleSurvey.surveyTitle,
    surveySummary: scheduleSurvey.surveySummary,
    minResponse: scheduleSurvey.minResponse,
    allowComments: scheduleSurvey.allowComments,
    emailSubject: scheduleSurvey.emailSubject,
    emailContent: scheduleSurvey.emailContent,
    companyId: scheduleSurvey.companyId,
  }
}

export function transformScheduleSurvey(scheduleSurvey) {
  return {
    id: scheduleSurvey.id,
    displayName: scheduleSurvey.displayName,
    name: scheduleSurvey.name,
    description: scheduleSurvey.description,
    validityDays: scheduleSurvey.validityDays,
    serviceCatalogId: scheduleSurvey.serviceCatalogId,
    surveyStatus: scheduleSurvey.surveyScheduleStatus,
    owner: scheduleSurvey.owner,
    departmentId: scheduleSurvey.departmentId,
    locationId: scheduleSurvey.locationId,
    tags: scheduleSurvey.tags,
    surveyTitle: scheduleSurvey.surveyTitle,
    surveySummary: scheduleSurvey.surveySummary,
    minResponse: scheduleSurvey.minResponse,
    allowComments: scheduleSurvey.allowComments,
    requesterGroup: scheduleSurvey.requesterGroup,
    excludeTechnicians: scheduleSurvey.excludeTechnicians,
    emailSubject: scheduleSurvey.emailSubject,
    emailContent: scheduleSurvey.emailContent,
    companyId: scheduleSurvey.companyId,
    flotoSchedule: transformFlotoSchedule(scheduleSurvey),
  }
}

export function transformScheduleSurveyForList(scheduleSurvey) {
  return {
    id: scheduleSurvey.id,
    displayName: scheduleSurvey.displayName,
    name: scheduleSurvey.name,
    description: scheduleSurvey.description,
    surveyStatus: scheduleSurvey.surveyScheduleStatus,
    validityDays: scheduleSurvey.validityDays,
    serviceCatalogId: scheduleSurvey.serviceCatalogId,
    owner: scheduleSurvey.owner,
    departmentId: scheduleSurvey.departmentId,
    locationId: scheduleSurvey.locationId,
    tags: scheduleSurvey.tags,
    requesterGroup: scheduleSurvey.requesterGroup,
    excludeTechnicians: scheduleSurvey.excludeTechnicians,
    enabled: !scheduleSurvey.disabled,
    nextFireTime: scheduleSurvey.nextFireTime,
    lastFiredTime: scheduleSurvey.lastFiredTime,
    emailSubject: scheduleSurvey.emailSubject,
    emailContent: scheduleSurvey.emailContent,
    companyId: scheduleSurvey.companyId,
    ...transformFlotoSchedule(scheduleSurvey),
  }
}
