import { transformRecursive } from './recursive'

export function transformCategoryRecursive(categories) {
  return transformRecursive(categories, transformCategory)
}

export function transformCategory(category) {
  return {
    id: category.id,
    value: category.id,
    label: category.name,
    order: category.objOrder,
    name: category.name,
    description: category.description,
    disabled: category.disabled,
    children: [],
    canDelete: category.deleteableOob,
    archived: category.removed,
    canEdit: category.updatebleOob,
    parentId: category.parentId,
    categoryPrefix: category.categoryPrefix,
    requesterGroupIds: category.requesterGroupIds,
    companyIds: category.companyIds,
  }
}

export function transformCategoryConfig(config) {
  return {
    id: config.id,
    allowedRequestLeafNode: config.allowedRequestLeafNode,
    allowedProblemLeafNode: config.allowedProblemLeafNode,
    allowedChangeLeafNode: config.allowedChangeLeafNode,
    allowedReleaseLeafNode: config.allowedReleaseLeafNode,
    reportHierarchy: config.reportHierarchy,
    categoryPrefix: config.categoryPrefix,
  }
}

export function transformCategoryConfigForServer(config) {
  return {
    allowedRequestLeafNode: config.allowedRequestLeafNode,
    allowedProblemLeafNode: config.allowedProblemLeafNode,
    allowedChangeLeafNode: config.allowedChangeLeafNode,
    allowedReleaseLeafNode: config.allowedReleaseLeafNode,
    reportHierarchy: config.reportHierarchy,
    categoryPrefix: config.categoryPrefix,
    requesterGroupIds: config.requesterGroupIds,
    companyIds: config.companyIds,
  }
}
