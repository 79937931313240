import SortBy from 'lodash/sortBy'
import { getRootPluaralTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import {
  transformQualificationForServer,
  transformQualification,
} from './qualification'

const rootTc = getRootPluaralTranslator()

export function ApprovalTypeOptions() {
  return [
    { text: rootTc('unanimous'), value: 'unanimous' },
    { text: rootTc('majority'), value: 'majority' },
    { text: rootTc('anyone'), value: 'anyone' },
    { text: rootTc('first_approval'), value: 'first_approval' },
  ]
}

export function transformApprovalWorkflow(approval) {
  return {
    id: approval.id,
    name: approval.name,
    moduleName: approval.refModel,
    assetTypeModuleName: approval.assetTypeModel,
    ...(approval.refId ? { parentId: approval.refId } : {}),
    qualifications: transformQualification(approval.qualification),
    actions: SortBy(approval.approvalActionStages || [], 'stage').map(
      transformApprovalAction
    ),
  }
}

function transformApprovalActionForServer(action, index) {
  return {
    stage: index + 1,
    subject: action.subject,
    description: action.description,
    approvalType: action.approvalType,
    groupIds: action.requesterGroups,
    technicianGroupIds: action.technicianGroups,
    approverIds: action.users,
    includeAssetUser: action.includeAssetUser,
    includeManagerUser: action.includeManagerUser,
    includeAssigneeManagerUser: action.includeAssigneeManagerUser,
    includeDepartmetHead: action.includeDepartmetHead,
    id: action.id,
  }
}

function transformApprovalAction(action) {
  return {
    guid: generateId(),
    subject: action.subject,
    description: action.description,
    approvalType: action.approvalType,
    requesterGroups: action.groupIds,
    technicianGroups: action.technicianGroupIds,
    users: action.approverIds,
    includeAssetUser: action.includeAssetUser,
    includeManagerUser: action.includeManagerUser,
    includeAssigneeManagerUser: action.includeAssigneeManagerUser,
    includeDepartmetHead: action.includeDepartmetHead,
    id: action.id,
  }
}

export function transformApprovalWorkflowForServer(approval) {
  return {
    name: approval.name,
    qualification: transformQualificationForServer(approval.qualifications),
    approvalActionStages: approval.actions.map((action, index) =>
      transformApprovalActionForServer(action, index)
    ),
    ...(approval.assetTypeModuleName
      ? { assetTypeModel: approval.assetTypeModuleName }
      : {}),
  }
}

export function transformApprovalSettings(settings) {
  return {
    approvalType: settings.approvalType,
    allowToCreateManualApproval: settings.approvalManualCreateAllowed,
    noteRequiredToReject: settings.approvalNoteRequiredForRejection,
    requestStatus: settings.approvalRequestDefaultStatus,
    requestApprovedStatus: settings.approvalRequestOnApprovedStatus,
    requestRejectedStatus: settings.approvalRequestOnRejectStatus,
    problemStatus: settings.approvalProblemDefaultStatus,
    problemApprovedStatus: settings.approvalProblemOnApprovedStatus,
    problemRejectedStatus: settings.approvalProblemOnRejectStatus,
    assetStatus: settings.approvalAssetDefaultStatus,
    assetApprovedStatus: settings.approvalAssetOnApprovedStatus,
    assetRejectedStatus: settings.approvalAssetOnRejectStatus,
    allowAddNoteOnApprovingRejectingApproval:
      settings.allowAddNoteOnApprovingRejectingApproval,
  }
}

export function transformApprovalSettingsForServer(settings) {
  return {
    approvalType: settings.approvalType,
    approvalManualCreateAllowed: settings.allowToCreateManualApproval,
    approvalNoteRequiredForRejection: settings.noteRequiredToReject,
    approvalRequestDefaultStatus: settings.requestStatus,
    approvalRequestOnApprovedStatus: settings.requestApprovedStatus,
    approvalRequestOnRejectStatus: settings.requestRejectedStatus,
    approvalProblemDefaultStatus: settings.problemStatus,
    approvalProblemOnApprovedStatus: settings.problemApprovedStatus,
    approvalProblemOnRejectStatus: settings.problemRejectedStatus,
    approvalAssetDefaultStatus: settings.assetStatus,
    approvalAssetOnApprovedStatus: settings.assetApprovedStatus,
    approvalAssetOnRejectStatus: settings.assetRejectedStatus,
    allowAddNoteOnApprovingRejectingApproval:
      settings.allowAddNoteOnApprovingRejectingApproval,
  }
}

export function transformApprovalForServer(approval) {
  return {
    subject: approval.subject,
    approvalStages: approval.actions.map((action, index) =>
      transformApprovalActionForServer(action, index)
    ),
    description: approval.description,
    approvalType: approval.approvalType,
    groupIds: approval.requesterGroups,
    approverIds: approval.users,
    runWorkFlow: approval.runWorkFlow,
  }
}

export function transformApproval(approval) {
  return {
    id: approval.id,
    subject: approval.subject,
    stageStatus: approval.stageStatus,
    activeStage: approval.currentStage,
    totalStage: approval.totalStage,
    approvalType: approval.approvalType,
    status: approval.approvalStatus,
    manual: approval.approvalWorkflowId === 0,
    createdAt: approval.createdTime,
    owner: approval.createdById,
    runWorkFlow: approval.runWorkFlow,
  }
}

export function transformApprovalStage(stage) {
  return {
    id: stage.id,
    subject: stage.subject,
    description: stage.description,
    status: stage.approvalStatus,
    approvalType: stage.approvalType,
    stats: {
      approved: stage.statusCount.approved,
      rejected: stage.statusCount.rejected,
      pending: stage.statusCount.pending,
      ignored: stage.statusCount.ignored,
      total: stage.totalApproval,
    },
  }
}

export function transformApprover(approver) {
  return {
    id: approver.id,
    email: approver.approverEmail,
    name: approver.approverName,
    approvalRequesterName:
      approver.approvalCreatorName || approver.approverName,
    approvalRequesterId: approver.approvalCreatorId || approver.createdById,
    status: approver.approverStatus,
    stageId: approver.refId,
    parentResource: approver.approvalModel,
    parentResourceId: approver.approvalParentId,
    updatedBy: approver.updatedById,
    updatedAt: approver.updatedTime,
    createdById: approver.createdById,
    userId: approver.userId,
    approvedUserId: approver.approvedUserId,
  }
}

export function transformApprovalAnalytics(analytics) {
  return {
    status: analytics.approvalStatus,
    stats: {
      approved: analytics.statusCount.approved,
      rejected: analytics.statusCount.rejected,
      pending: analytics.statusCount.pending,
      total: analytics.totalApproval,
      ignored: analytics.statusCount.ignored || 0,
      needed: analytics.totalApprovalNeeded || 0,
    },
    approvalType: analytics.approvalType,
  }
}

export function transformMyApproval(approval) {
  return {
    id: approval.approverId,
    name: approval.name,
    approvalRequesterName:
      approval.approvalCreatorName || approval.requesterName,
    email: approval.approverEmail,
    approverId: approval.approverId,
    parentId: approval.refId,
    moduleName: approval.refModle,
    subject: approval.subject,
    description: approval.description,
    status: approval.status,
    createdAt: approval.createdDate,
    updatedAt: approval.updatedDate,
    updatedBy: approval.updatedById,
    approvalType: approval.approvalType,
    module: approval.refModel,
    approvedUserId: approval.approvedUserId,
    requesterId: approval.requesterId,
  }
}
