import Constants from '@constants'
import { transformAttachment, transformAttachmentForServer } from './attachment'

export function transformTaskType(taskType) {
  return {
    id: taskType.id,
    name: taskType.name,
    systemName: taskType.systemName,
    canDelete: taskType.deleteableOob,
    canEdit: taskType.updatebleOob,
    order: taskType.objOrder,
  }
}

export function transformTaskTypeForServer(taskType) {
  return {
    name: taskType.name,
  }
}

export function transformTask(task) {
  return {
    id: task.id,
    name: task.name,
    subject: task.subject,
    moduleName: task.refModel,
    ...(task.refId ? { parentId: task.refId } : {}),
    ...(task.reference ? { reference: task.reference } : {}),
    statusId: task.statusId,
    groupId: task.groupId,
    technicianId: task.technicianId,
    description: task.description,
    fileAttachemnts: (task.fileAttachemnts || []).map(transformAttachment),
    taskTypeId: task.taskTypeId,
    priorityId: task.priorityId,
    estimatedTime: task.estimatedTime,
    estimatedTimeUnit: task.estimatedTimeUnit,
    timeRange: {
      startTime: task.startTime,
      endTime: task.endTime,
    },
    stage: task.taskDependencyStage,
    order: task.objOrder,
    notifyBeforeHours: task.notifyBeforeHours,
    notifyBeforeHoursTimeUnit: task.notifyBeforeHoursTimeUnit,
    createdAt: task.createdTime,
    owner: task.createdById,
    closedAt: task.lastClosedTime,
    ...(task.refModel === Constants.PROJECT
      ? {
          duration: task.duration,
          completion: task.completion,
          predecessors: task.predecessors,
          successors: task.successors,
        }
      : {}),
  }
}

export function transformTaskForServer(task) {
  return {
    name: task.name,
    subject: task.subject,
    statusId: task.statusId,
    groupId: task.groupId,
    technicianId: task.technicianId,
    ...('description' in task ? { description: task.description || '' } : {}),
    ...(task.fileAttachemnts
      ? {
          fileAttachemnts: (task.fileAttachemnts || []).map(
            transformAttachmentForServer
          ),
        }
      : {}),
    taskDependencyStage: task.stage,
    estimatedTime: task.estimatedTime,
    estimatedTimeUnit: task.estimatedTimeUnit,
    // objOrder: task.order, // removed in new fixed
    taskTypeId: task.taskTypeId,
    priorityId: task.priorityId,
    startTime: (task.timeRange || {}).startTime,
    endTime: (task.timeRange || {}).endTime,
    notifyBeforeHours: task.notifyBeforeHours,
    notifyBeforeHoursTimeUnit: task.notifyBeforeHoursTimeUnit,
    ...(task.moduleName === Constants.PROJECT
      ? {
          duration: Number(task.duration),
          completion: task.completion,
          predecessors: task.predecessors,
          successors: task.successors,
        }
      : {}),
  }
}

export function tranformMovedTaskForServer(task) {
  return {
    taskId: task.id,
    newObjOrder: task.newOrder,
    newStage: task.stage,
  }
}

export const defaultTask = {
  subject: undefined,
  statusId: undefined,
  groupId: undefined,
  technicianId: 0,
  description: undefined,
  fileAttachemnts: undefined,
  taskTypeId: 1,
  priorityId: 1,
  notifyBeforeHours: 0,
  estimatedTimeUnit: 'hours',
  notifyBeforeHoursTimeUnit: 'hours',
  timeRange: {
    startTime: undefined,
    endTime: undefined,
  },
}
export const defaultTaskForWorkflow = {
  technicianId: 0,
  taskTypeId: 1,
  priorityId: 1,
  notifyBeforeHours: 0,
  estimatedTimeUnit: 'hours',
  notifyBeforeHoursTimeUnit: 'hours',
}
