import { transformAttachmentForServer, transformAttachment } from './attachment'

export function transformSolution(data) {
  return {
    text: data.solution,
    createdAt: data.solutionUpdatedTime,
    owner: data.solutionUpdatedById,
    fileAttachments: (data.solutionFileAttachments || []).map(
      transformAttachment
    ),
    conversationState: data.conversationState || 'published',
  }
}

export function transformConclusion(data) {
  return {
    text: data.conclusion,
    createdAt: data.conclusionUpdatedTime,
    owner: data.conclusionUpdatedById,
    fileAttachments: (data.conclusionFileAttachments || []).map(
      transformAttachment
    ),
    conversationState: data.conversationState || 'published',
  }
}

export function transformDiagnosis(data) {
  return {
    text: data.diagnosis,
    createdAt: data.diagnosisUpdatedTime,
    owner: data.diagnosisUpdatedById,
    fileAttachments: (data.diagnosisFileAttachments || []).map(
      transformAttachment
    ),
    conversationState: data.conversationState || 'published',
  }
}

export function transformWorkUnit(data, propKey) {
  return {
    text: data[propKey],
    fileAttachments: (data[`${propKey}FileAttachments`] || []).map(
      transformAttachment
    ),
    conversationState: data.conversationState || 'published',
  }
}

export function transformWorkUnitForServer(data, propKey) {
  return {
    [propKey]: data.text,
    [`${propKey}FileAttachments`]: (data.fileAttachments || []).map(
      transformAttachment
    ),
    conversationState: data.conversationState || 'published',
  }
}

export function transformConversation(data) {
  return {
    text: data.details,
    fileAttachments: (data.fileAttachments || []).map(transformAttachment),
    createdAt: data.createdTime,
    type: data.conversationType,
    noteAsFirstResponse: data.noteAsFirstResponse,
    owner: data.createdById,
    canEdit: data.updatebleOob,
    canDelete: data.deleteableOob,
    rating: data.rating,
    isPrivate: !data.isPublic,
    conversationState: data.conversationState || 'published',
    id: data.id,
    ...(data.fieldValueDetails
      ? { fieldValueDetails: data.fieldValueDetails }
      : {}),
    ...(data.subject ? { subject: data.subject } : {}),
    ...((data.emailIds || []).length > 0 ? { emailIds: data.emailIds } : {}),
    ...((data.ccEmailIds || []).length > 0
      ? { ccEmailIds: data.ccEmailIds }
      : {}),
  }
}

export function transformDiagnosisForServer(data) {
  return {
    diagnosis: data.text,
    diagnosisFileAttachments: (data.fileAttachments || []).map(
      transformAttachmentForServer
    ),
    conversationState: data.conversationState || 'published',
  }
}

export function transformSolutionForServer(data) {
  return {
    solution: data.text,
    solutionFileAttachments: (data.fileAttachments || []).map(
      transformAttachmentForServer
    ),
    conversationState: data.conversationState || 'published',
  }
}

export function transformConclusionForServer(data) {
  return {
    conclusion: data.text,
    conclusionFileAttachments: (data.fileAttachments || []).map(
      transformAttachmentForServer
    ),
    conversationState: data.conversationState || 'published',
  }
}

export function transformConversationForServer(data) {
  return {
    details: data.text,
    fileAttachments: data.fileAttachments,
    conversationType: data.type,
    noteAsFirstResponse: data.noteAsFirstResponse,
    conversationState: data.conversationState || 'published',
    ...(data.subject ? { subject: data.subject } : {}),
    ...((data.emailIds || []).length > 0
      ? { emailIds: data.emailIds }
      : { emailIds: [] }),
    ...((data.ccEmailIds || []).length > 0
      ? { ccEmailIds: data.ccEmailIds }
      : { ccEmailIds: [] }),
  }
}
