import Invert from 'lodash/invert'
import { generateId } from '@utils/id'
import { transformValueTypeForServer, transformValueType } from './value-type'
import {
  transformField,
  buildInputTypeForField,
  getAdditionalFieldProps,
} from './form'
import { transformApiField, transformApiFieldForServer } from '@data/form'
import {
  transformAnnouncement,
  transformAnnouncementForServer,
} from '@data/announcement'
import { dropdownTypeMap } from './qualification'

const actionMap = {
  email: 'EmailActionRest',
  sms: 'SmsActionRest',
  setValue: 'SetFieldActionRest',
  watcher: 'WatchersActionRest',
  plugin: 'PluginActionRest',
  approval: 'ApprovalWorkflowActionRest',
  priority_matrix: 'UpdatePriorityByPriorityMatrixActionRest',
  api: 'APIActionRest',
  relation: 'RelationActionRest',
  task: 'CreateTaskActionRest',
  addtask: 'TaskActionRest',
  custom_field: 'CustomFieldActionRest',
  smart_field: 'SmartFieldActionRest',
  reply_to_requester: 'ReplyToRequesterActionRest',
  announcement: 'AnnouncementActionRest',
}

function isTaskActionRest(action) {
  return action.type === 'TaskActionRest' || action.type === 'addtask'
}

function isEmailAction(action) {
  return action.type === 'EmailActionRest' || action.type === 'email'
}
function isCreateTaskAction(action) {
  return action.type === 'CreateTaskActionRest' || action.type === 'task'
}
function isSmsAction(action) {
  return action.type === 'SmsActionRest' || action.type === 'sms'
}

// eslint-disable-next-line
function isWatcherAction(action) {
  return action.type === 'WatchersActionRest' || action.type === 'watcher'
}

function isPluginAction(action) {
  return action.type === 'PluginActionRest' || action.type === 'plugin'
}

function isApiAction(action) {
  return action.type === 'APIActionRest' || action.type === 'api'
}

function isSmartFieldAction(action) {
  return action.type === 'SmartFieldActionRest' || action.type === 'smart_field'
}

function isValueAction(action) {
  return action.type === 'SetFieldActionRest' || action.type === 'setValue'
}

function isReplyAction(action) {
  return (
    action.type === 'ReplyToRequesterActionRest' ||
    action.type === 'reply_to_requester'
  )
}
function isAnnouncementAction(action) {
  return (
    action.type === 'AnnouncementActionRest' || action.type === 'announcement'
  )
}

function transformFieldActionTypeMap(data) {
  const obj = {}
  Object.keys(data || {}).map((key) => {
    obj[key] = (data[key] || []).map((i) => ({
      ...i,
      text: i.displayName,
      key: i.setFieldActionType,
    }))
  })
  return obj
}

function transformFieldDetailsMap(data) {
  const obj = {}
  Object.keys(data || {}).map((key) => {
    obj[key] = (data[key] || []).map((i) => ({
      ...i,
      text: i.displayName,
      key: i.referencedField,
    }))
  })
  return obj
}

export function transformAvailableAction(action) {
  const currentMap = Invert(actionMap)
  if (!currentMap[action.type]) {
    throw new Error(`${action.type} is not supported`)
  }
  let field
  if (action.field) {
    field = transformField(action.field)
  }
  return {
    value: action.fieldName,
    label: action.displayName,
    isCustomField: action.customField,
    type: currentMap[action.type],
    // inputType: action.inputTypes,
    inputType: dropdownTypeMap[action.inputTypes]
      ? 'dropdown'
      : action.inputTypes,
    ...(dropdownTypeMap[action.inputTypes]
      ? dropdownTypeMap[action.inputTypes]()
      : {}),
    valueType: action.primitiveType,
    ...(field
      ? {
          inputType: buildInputTypeForField(field),
          multiple: field.type === 'checkbox',
          valueType:
            field.type === 'number'
              ? field.attributes.allowDecimal
                ? 'double'
                : 'integer'
              : action.primitiveType,
          customFieldType: field.type,
          ...getAdditionalFieldProps(field),
        }
      : {}),
    fieldActionTypeOptions: (action.fieldActionTypeDetails || []).map((i) => ({
      ...i,
      text: i.displayName,
      key: i.setFieldActionType,
    })),
    fieldDetailsOptions: (action.fieldDetails || []).map((i) => ({
      ...i,
      text: i.displayName,
      key: i.referencedField,
    })),
    fieldActionTypeMap: transformFieldActionTypeMap(action.fieldActionTypeMap),
    fieldDetailsMap: transformFieldDetailsMap(action.fieldDetailsMap),
  }
}

export function transformAction(action) {
  const currentMap = Invert(actionMap)
  if (!currentMap[action.type]) {
    throw new Error(`${action.type} is not supported`)
  }
  return {
    guid: generateId(),
    id: action.id,
    isCustomField: action.customField,
    ...(isValueAction(action) ? transformValueType(action.fieldValue) : {}),
    setFieldActionType: action.setFieldActionType,
    action: action.fieldName,
    actionType: currentMap[action.type],
    description: action.description,
    ...(isEmailAction(action)
      ? {
          emails: action.recepientEmails,
          ccEmails: action.ccEmails,
          technicianGroups:
            action.groupSetActionType === 'from'
              ? transformValueType(action.groupFieldValue).value
              : action.groupIds,
          subject: action.subject,
          content: action.emailTemplateContent,
          groupSetActionType: action.groupSetActionType,
        }
      : isCreateTaskAction(action)
      ? {
          subject: action.subject,
          technicianId: action.technicianId,
          taskTypeId: action.taskTypeId,
          statusId: action.statusId,
          priorityId: action.priorityId,
          groupId: action.groupId,
          dueBy: action.dueBy,
          notifyBeforeHours: action.notifyBeforeHours,
          notifyBeforeHoursTimeUnit: action.notifyBeforeHoursTimeUnit,
          taskDescription: action.taskDescription,
          estimatedTime: action.estimatedTime,
          estimatedTimeUnit: action.estimatedTimeUnit,
          associateParentEntity: action.associateParentEntity,
        }
      : isTaskActionRest(action)
      ? {
          subject: action.subject,
          taskTypeId: action.taskTypeId,
          startTime: action.startTime,
          endTime: action.endTime,
          statusId: action.statusId,
          priorityId: action.priorityId,
          estimatedTime: action.estimatedTime,
          notifyBeforeHours: action.notifyBeforeHours,
          notifyBeforeHoursTimeUnit: action.notifyBeforeHoursTimeUnit,
          estimatedTimeUnit: action.estimatedTimeUnit,
          taskDescription: action.taskDescription,
          fileAttachements: action.fileAttachements,
          technicianSetActionType: action.technicianSetActionType,
          groupSetActionType: action.groupSetActionType,
          technicianId:
            action.technicianSetActionType === 'from'
              ? (transformValueType(action.technicianFieldValue) || {}).value
              : action.technicianId,
          groupId:
            action.groupSetActionType === 'from'
              ? transformValueType(action.groupFieldValue).value
              : action.groupId,
        }
      : isSmsAction(action)
      ? {
          technicianGroups: action.groupIds,
          content: action.content,
          contactNumber: action.contactNumber,
        }
      : isWatcherAction(action)
      ? {
          technicians:
            action.technicianSetActionType === 'from'
              ? (transformValueType(action.technicianFieldValue) || {}).value
              : action.technicianIds,
          technicianGroups:
            action.groupSetActionType === 'from'
              ? transformValueType(action.groupFieldValue).value
              : action.technicianGroupIds,
          technicianSetActionType: action.technicianSetActionType,
          groupSetActionType: action.groupSetActionType,
        }
      : isPluginAction(action)
      ? {
          pluginIdentifier: action.pluginIdentifier,
          inputMapping: (action.inputMapping || []).map((m) => ({
            ...m,
            guid: generateId(),
          })),
          outputMapping: (action.outputMapping || []).map((m) => ({
            ...m,
            guid: generateId(),
          })),
        }
      : isApiAction(action)
      ? {
          ...transformApiField(action),
        }
      : isSmartFieldAction(action)
      ? {
          fields: action.fields,
        }
      : isReplyAction(action)
      ? {
          content: action.responseTemplateContent,
          fileAttachments: action.attachment,
        }
      : isAnnouncementAction(action)
      ? {
          ...transformAnnouncement(action),
        }
      : {}),
  }
}

export function transformActionForServer(action) {
  if (!actionMap[action.actionType]) {
    throw new Error(`${action.actionType} is not supported`)
  }
  return {
    ...(!isEmailAction({ type: action.actionType })
      ? { customField: action.isCustomField }
      : {}),
    ...(!isCreateTaskAction({ type: action.actionType })
      ? { customField: action.isCustomField }
      : {}),
    ...(!isSmsAction({ type: action.actionType })
      ? { customField: action.isCustomField }
      : {}),
    ...(!isTaskActionRest({ type: action.actionType })
      ? { customField: action.isCustomField }
      : {}),
    ...(isValueAction({ type: action.actionType })
      ? {
          fieldValue: transformValueTypeForServer(
            action.setFieldActionType === 'from' ? 'field' : action.valueType,
            action.value
          ).value,
          setFieldActionType: action.setFieldActionType,
        }
      : {}),
    ...(action.id ? { id: action.id } : {}),
    fieldName: action.action,
    type: actionMap[action.actionType],
    description: action.description,
    ...(action.actionType === 'email'
      ? {
          recepientEmails: action.emails,
          ccEmails: action.ccEmails,
          subject: action.subject,
          emailTemplateContent: action.content,
          groupSetActionType: action.groupSetActionType,
          ...(action.groupSetActionType === 'from'
            ? {
                groupFieldValue: transformValueTypeForServer(
                  'field',
                  action.technicianGroups
                ).value,
              }
            : {
                groupIds: action.technicianGroups,
              }),
        }
      : action.actionType === 'task'
      ? {
          subject: action.subject,
          technicianId: action.technicianId,
          taskTypeId: action.taskTypeId,
          statusId: action.statusId,
          priorityId: action.priorityId,
          groupId: action.groupId,
          dueBy: action.dueBy,
          notifyBeforeHours: action.notifyBeforeHours,
          notifyBeforeHoursTimeUnit: action.notifyBeforeHoursTimeUnit,
          taskDescription: action.taskDescription,
          estimatedTime: action.estimatedTime,
          estimatedTimeUnit: action.estimatedTimeUnit,
          associateParentEntity: action.associateParentEntity,
        }
      : action.actionType === 'sms'
      ? {
          groupIds: action.technicianGroups,
          content: action.content,
          contactNumber: action.contactNumber,
        }
      : action.actionType === 'addtask'
      ? {
          subject: action.subject,
          taskTypeId: action.taskTypeId,
          startTime: action.startTime,
          endTime: action.endTime,
          statusId: action.statusId,
          priorityId: action.priorityId,
          estimatedTime: action.estimatedTime,
          notifyBeforeHours: action.notifyBeforeHours,
          notifyBeforeHoursTimeUnit: action.notifyBeforeHoursTimeUnit,
          estimatedTimeUnit: action.estimatedTimeUnit,
          taskDescription: action.taskDescription,
          fileAttachements: action.fileAttachements,
          technicianSetActionType: action.technicianSetActionType,
          groupSetActionType: action.groupSetActionType,
          ...(action.groupSetActionType === 'from'
            ? {
                groupFieldValue: transformValueTypeForServer(
                  'field',
                  action.groupId
                ).value,
              }
            : {
                groupId: action.groupId,
              }),
          ...(action.technicianSetActionType === 'from'
            ? {
                technicianFieldValue: transformValueTypeForServer(
                  'field',
                  action.technicianId
                ).value,
              }
            : {
                technicianId: action.technicianId,
              }),
        }
      : action.actionType === 'watcher'
      ? {
          technicianSetActionType: action.technicianSetActionType,
          groupSetActionType: action.groupSetActionType,
          ...(action.groupSetActionType === 'from'
            ? {
                groupFieldValue: transformValueTypeForServer(
                  'field',
                  action.technicianGroups
                ).value,
              }
            : {
                technicianGroupIds: action.technicianGroups,
              }),
          ...(action.technicianSetActionType === 'from'
            ? {
                technicianFieldValue: transformValueTypeForServer(
                  'field',
                  action.technicians
                ).value,
              }
            : {
                technicianIds: action.technicians,
              }),
        }
      : action.actionType === 'plugin'
      ? {
          pluginIdentifier: action.pluginIdentifier,
          inputMapping: action.inputMapping,
          outputMapping: action.outputMapping,
        }
      : action.actionType === 'api'
      ? {
          ...transformApiFieldForServer(action),
        }
      : action.actionType === 'smart_field'
      ? {
          fields: action.fields,
        }
      : action.actionType === 'reply_to_requester'
      ? {
          responseTemplateContent: action.content,
          attachment: action.fileAttachments,
        }
      : action.actionType === 'announcement'
      ? {
          ...transformAnnouncementForServer(action),
        }
      : {}),
  }
}
